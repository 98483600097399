import idx from 'idx';

import { SpaceNameQuery } from './SpaceNameQuery.graphql';

export const getSpaceNameFromResults = (results) => {
	const errors = results.filter((result) => result.error).map((result) => result.error);
	if (errors.length) {
		throw new Error(errors.join('\n\n'));
	}
	return idx(results[0], (_) => _.data.space.name) || '';
};

export const getSpaceNameQueryProps = (spaceKey) => {
	return [
		{
			query: SpaceNameQuery,
			variables: { spaceKey },
			skip: !spaceKey,
		},
	];
};
