import React from 'react';

import { LoadableAfterPaint } from '@confluence/loadable';

import { TeammatePresenceLivePagePlaceholder } from './TeammatePresenceLivePagePlaceholder';
import { TeammatePresencePlaceholder } from './TeammatePresencePlaceholder';

export const TeammatePresenceLoader = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-TeammatePresence" */ './TeammatePresence'))
			.TeammatePresence,
	loading: ({ isLivePage }) => {
		if (isLivePage) {
			return <TeammatePresenceLivePagePlaceholder />;
		} else {
			return <TeammatePresencePlaceholder />;
		}
	},
});
