import React from 'react';

import { Box, Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { fg } from '@confluence/feature-gating';
import { DocumentSpaceTitle } from '@confluence/document-title';

import { HubSettingsEntryButton } from '../hub-settings/HubSettingsEntryButton';

const headerContainerStyles = xcss({
	justifyContent: 'space-between',
	padding: 'space.250',
	position: 'absolute',
	right: 'space.0',
	width: '100%',
	alignItems: 'center',
});

const editButtonStyles = xcss({
	background: token('elevation.surface'),
	boxShadow: 'elevation.shadow.raised',
	borderRadius: '100%',
});

const settingsButtonStyles = xcss({
	background: token('elevation.surface'),
	boxShadow: 'elevation.shadow.raised',
	borderRadius: 'border.radius.100',
});
type CompanyHubGroupedButtonsProps = {
	editButton: React.ReactNode;
	hasEditPermissions?: boolean;
	spaceKey: string;
};

export const CompanyHubGroupedButtons = ({
	editButton,
	hasEditPermissions,
	spaceKey,
}: CompanyHubGroupedButtonsProps) =>
	hasEditPermissions ? (
		<>
			<Flex xcss={headerContainerStyles}>
				{fg('company_hub_consolidated_settings') ? (
					<Box xcss={settingsButtonStyles}>
						<HubSettingsEntryButton />
					</Box>
				) : (
					<div />
				)}
				<Box xcss={editButtonStyles}>{editButton}</Box>
			</Flex>
			<DocumentSpaceTitle spaceKey={spaceKey} />
		</>
	) : null;
